import React, { useState, useEffect, useRef } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import {
  axiosSeminarRoute,
  axiosVb_SemRefRoute,
  axiosWordPressSeminarRoute,
} from "../helpers/axios";

import SeminarAddModal from "./SeminarAddModal";
import SelectedSeminarInputs from "./SelectedSeminarInputs";

import SeminarSelectGrid from "./SeminarSelectGrid";
import SeminarConfirmedGrid from "./SeminarConfirmedGrid";
import { useLocation } from "react-router-dom";

import { DateTime } from "luxon";
import * as XLSX from "xlsx";

const EditSeminar = ({ filter }) => {
  const {
    token,
    notify,
    seminarSelection,
    raumeSelection,
    referentSelection,
    seminarReferenteSelection,
    seminarInputs,
    setSeminarInputs,
    getData,
    seminarParticipantsList,
    storedClientSelection,
    invoiceSelection,
    getInvoiceData,
    editSeminar,
    setEditSeminar,
    downloadHandler,
    getStoredClientData,
    getSemPartData,
    WPToken,
    selectSeminarOnFirstLoad,
    setSelectSeminarOnFirstLoad,
    triggerSeminarSelect,
    setTriggerSeminarSelect,
    idFilter,
    dateRangeFilter,
    exactDateFilter,
    setIdFilter,
    setExactDateFilter,
    setDateRangeFilter,
    wordPressAudienceOptions,
  } = useOutletContext();

  const [open, setOpen] = useState(false);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const gridRefSeminar = useRef();

  const [loading, setLoading] = useState(false);

  const [seminarReferentListInputs, setSeminarReferentListInputs] = useState(
    {}
  );
  const [formErrorHandling, setFormErrorHandling] = useState(null);

  const [currentSection, setCurrentSection] = useState(1);

  const [referenteArray, setReferenteArray] = useState([]);

  const [referenteAddData, setReferenteAddData] = useState({});

  const [loadingDyn, setLoadingDyn] = useState(false);

  const [query, setQuery] = useState({
    semid: "",
    anfangsdatum: "",
    enddatum: "",
    thema: "",
    daterange: "",
  });

  const currentReferentHandler = (e) => {
    const id = Number(e);

    const filterReferentById = referentSelection?.filter(
      (referent) => referent.id === id
    )[0];

    setSeminarReferentListInputs((current) => ({
      ...current,
      ...filterReferentById.attributes,
      id_seminar: seminarInputs.id,
      id_referent: filterReferentById.id,
    }));
  };

  const addDays = (date, days) => {
    let inputdate = new Date(date);
    inputdate.setDate(inputdate.getDate() + days);
    return inputdate;
  };

  const setTime = (params, type = "time") => {
    if (params !== null) {
      let isoDate = params;

      var d = new Date(isoDate);

      if (type === "time") {
        return d.toISOString().substring(11, 16);
      }
      if (type === "date") {
        return DateTime.fromJSDate(d).toFormat("yyyy-MM-dd");
      }
    } else {
      return "";
    }
  };

  const handleSeminarChange = (value, name) => {
    formErrorHandling &&
      setFormErrorHandling((prevState) =>
        prevState.filter((e) => e.path[0] !== name)
      );
    setSeminarInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSeminarReferentChange = (value, name) => {
    formErrorHandling &&
      setFormErrorHandling((prevState) =>
        prevState.filter((e) => e.path[0] !== name)
      );
    setSeminarReferentListInputs((values) => ({ ...values, [name]: value }));
  };

  const updateWPHandler = async (data, table) => {
    const wptoken = WPToken;

    let formdata = new FormData();

    if (table === "seminar") {
      const {
        id,
        attributes: {
          id_raum,
          thema,
          lang_thema,
          preis,
          datum,
          anfangs_zeit,
          end_datum,
          end_zeit,
          participants,
          conditions_of_participation,
          focus_areas,
          audiences,
          wp_published,
        },
      } = data;

      const findraume = raumeSelection.find((raume) => raume.id === id_raum);

      formdata.append("title", `${!thema ? "" : thema}`);
      formdata.append("content", `${!lang_thema ? "" : lang_thema}`);
      formdata.append("status", `${wp_published ? "publish" : "draft"}`);
      formdata.append("acf[most_popular]", "0");
      formdata.append("acf[seminar_id]", `${id}`);
      formdata.append("acf[price]", `${preis}`);
      formdata.append("acf[start_date]", `${setTime(datum, "date")}`);
      formdata.append("acf[start_time]", `${setTime(anfangs_zeit, "time")}`);
      formdata.append("acf[end_date]", `${setTime(end_datum, "date")}`);
      formdata.append("acf[end_time]", `${setTime(end_zeit, "time")}`);
      formdata.append(
        "acf[participants]",
        `${!participants ? "" : participants}`
      );
      formdata.append(
        "acf[conditions_of_participation]",
        `${!conditions_of_participation ? "" : conditions_of_participation}`
      );
      formdata.append("acf[focus_areas]", `${!focus_areas ? "" : focus_areas}`);

      if (findraume) {
        let stateid = findraume?.attributes?.bundesland_id || "";

        // if bundesland_id is 45 change to 5. This is because we merge the states of Berlin (id 45) and Brandenburg (id 5) in the WordPress backend.
        if (stateid === "45") {
          stateid = "5";
        }

        formdata.append("acf[city]", `${findraume.attributes.ort}`);
        formdata.append(
          "acf[location]",
          findraume.id === 423
            ? "Online"
            : `${findraume.attributes.bezeichnung || ""}, ${
                findraume.attributes.strasse || ""
              } ${findraume.attributes.haus_nr || ""}, ${
                findraume.attributes.plz || ""
              }, ${findraume.attributes.ort || ""}`
        );

        formdata.append(
          "terms[state][]",
          `${findraume.id !== 423 ? stateid : ""}`
        );
      }

      formdata.append("terms[audience]", `${audiences || ""}`);
    }

    if (table === "semref") {
      formdata.append("acf[speaker]", data.html);
    }

    try {
      let result;
      result = await axiosWordPressSeminarRoute.post(
        `/${data?.attributes?.id_wp_entry ? data.attributes.id_wp_entry : ""}`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${wptoken}`,
          },
        }
      );

      if (result?.data) {
        return result.data;
      } else {
      }
    } catch (err) {
      // const deleteResult = await axiosSeminarRoute.delete(`/${data.id}`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      // console.log(deleteResult);

      notify("error", "Etwas ist schief gelaufen...");

      setLoading(false);

      console.log(err);
    }
  };

  const addReferenteToWPSite = async (seminarid, id_wp_entry) => {
    const seminarReferenteResult = await axiosVb_SemRefRoute.get("/", {
      params: {
        "filters[id_seminar][id][$eq]": seminarid,
        populate: "*",
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const seminarReferenteResultData = seminarReferenteResult?.data?.data;

    const referentHtmlGen = seminarReferenteResultData.map((referent) => {
      const { vorname, name, bemerkung } =
        referent.attributes.id_referent.data.attributes;

      return `<div style="padding-bottom: 10px"><strong style="padding-bottom: 10px">${
        vorname || ""
      } ${name || ""}</strong>${bemerkung || ""}</div>
    `;
    });

    if (seminarid) {
      await updateWPHandler(
        {
          attributes: { id_wp_entry },
          html:
            seminarReferenteResultData.length === 0
              ? ""
              : referentHtmlGen.join(""),
        },
        "semref"
      );
    }

    return;
  };

  const checkFilterAndGetData = async () => {
    if (idFilter) {
      await getData(
        filter && {
          "filters[id][$eq]": query.semid,
        }
      );
    } else if (exactDateFilter) {
      await getData(
        filter && {
          "filters[datum][$gte]": addday(query.anfangsdatum, 0),
        }
      );
    } else if (dateRangeFilter) {
      await getData(
        filter && {
          "filters[datum][$gte]": addday(query.daterange[0], 0),
          "filters[datum][$lte]": addday(query.daterange[1], 0),
        }
      );
    } else {
      await getData();
    }
    return;
  };

  const updateSeminarHandler = async (event, open) => {
    const data = {
      ...seminarInputs,
    };

    setLoading(true);

    try {
      let result;
      if (open === "edit") {
        result = await axiosSeminarRoute.put(
          `/${seminarInputs.id}`,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (open === "add" || open === "copy") {
        // strip the id and wordpress metadata from the seminarInputs if user is copying a seminar
        const { id, id_wp_entry, wp_link, max_teilnehmer, ...noidandwp } =
          seminarInputs;

        const inputDate = new Date(seminarInputs.end_datum);

        const data = {
          ...(open === "copy" ? noidandwp : seminarInputs),
          zahl_termin: addDays(inputDate, 7),
          frage_termin: addDays(inputDate, -14),
          max_teilnehmer: 0,
        };

        result = await axiosSeminarRoute.post(
          `/`,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (result?.data) {
        const wpResult = await updateWPHandler(result.data.data, "seminar");

        if ((wpResult?.id && open === "add") || open === "copy") {
          await axiosSeminarRoute.put(
            `/${result.data.data.id}`,
            {
              data: {
                id_wp_entry: wpResult.id,
                wp_link: wpResult.generated_slug,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }

        notify(
          "info",
          `${open === "edit" ? "Aktualisiert" : "Hinzugefügt"} Seminar`
        );

        if (open === "add" || open === "copy") {
          notify("info", `Neue Seminar-ID ist: ${result.data.data.id}`, {
            position: "top-center",
            autoClose: false,
          });

          navigator.clipboard.writeText(result.data.data.id);
        }

        if (referenteArray.length > 0) {
          (async () => {
            for await (const [index, referent] of referenteArray.entries()) {
              await addSeminarReferentHandler(
                event,
                "new",
                result.data.data.id,
                null,
                referent,
                index === referenteArray.length - 1,
                wpResult.id
              );
            }
          })();
        }

        open !== "edit" && setSeminarInputs({});

        setEditSeminar(false);
        await checkFilterAndGetData();
        await getInvoiceData();
        setLoading(false);
        setOpen(false);
        setCurrentSection(1);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setReferenteAddData({ ist_mehrwert_steuer: true });
        setReferenteArray([]);
        setTriggerSeminarSelect(true);
      }
    } catch (err) {
      console.log(err);
      setFormErrorHandling(err.response.data.error.details.errors);
      setLoading(false);
      notify("error", "Etwas ist schief gelaufen...");
    }
  };

  const addSeminarReferentHandler = async (
    event,
    action,
    idseminar,
    semrefid,
    semrefdata,
    checkforlength,
    id_wp_entry
  ) => {
    const {
      id_seminar,
      id_referent,
      brutto_honorar_euro,
      ist_mehrwert_steuer,
      zahlung_methode,
    } = seminarReferentListInputs;
    event.preventDefault();
    try {
      let result;

      if (action === "add" || action === "new") {
        result = await axiosVb_SemRefRoute.post(
          `/`,
          {
            data: {
              id_seminar: action === "new" ? idseminar : id_seminar,
              id_referent:
                action === "new" ? semrefdata.id_referent : id_referent,
              ref_brutto_honorar_euro:
                action === "new"
                  ? semrefdata.brutto_honorar_euro
                  : brutto_honorar_euro,
              ist_mehrwert_steuer:
                action === "new"
                  ? semrefdata.ist_mehrwert_steuer
                  : ist_mehrwert_steuer,
              zahlung_methode:
                action === "new" ? semrefdata.zahlung_methode : zahlung_methode,
              sonstige_kosten_name:
                seminarReferentListInputs.sonstige_kosten_name || null,
              sonstige_kosten_wert:
                seminarReferentListInputs.sonstige_kosten_wert || null,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (action === "delete") {
        result = await axiosVb_SemRefRoute.delete(`/${semrefid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      if (result?.data) {
        if (action === "new" && !checkforlength) {
          return;
        }

        await addReferenteToWPSite(
          idseminar,
          action === "new"
            ? id_wp_entry
            : seminarInputs?.id_wp_entry
            ? seminarInputs.id_wp_entry
            : null
        );

        await checkFilterAndGetData();

        notify(
          "info",
          action === "add" || action === "new"
            ? "Added Referent to seminar"
            : "Referent Deleted"
        );
        setSeminarReferentListInputs({});
      }
    } catch (err) {
      setLoading(false);
      console.log(err.response);
    }
  };

  const addday = (date, plus = 0) => {
    return new Date(
      new Date(date).setDate(new Date(date).getDate() + Number(plus))
    );
  };

  const filterHandler = async (e, action, filter = false) => {
    e.preventDefault();
    setLoadingDyn(action);
    setSeminarInputs({});

    const semid = searchParams.get("seminarid");

    if (semid) {
      setSearchParams((params) => {
        params.delete("seminarid");
        return params;
      });
    }

    if (filter === false) {
      await getData();
      setQuery({
        semid: "",
        anfangsdatum: "",
        enddatum: "",
        thema: "",
        daterange: "",
      });
      setIdFilter(false);
      setExactDateFilter(false);
      setDateRangeFilter(false);
    } else {
      if (action === "semid") {
        await getData(
          filter && {
            "filters[id][$eq]": query.semid,
          }
        );
        setIdFilter(filter);
      }

      if (action === "exactdate") {
        await getData(
          filter && {
            "filters[datum][$gte]": addday(query.anfangsdatum, 0),
            // "filters[datum][$lte]": addday(query.anfangsdatum, 1),
          }
        );
        setExactDateFilter(filter);
      }

      if (action === "daterange") {
        await getData(
          filter && {
            "filters[datum][$gte]": addday(query.daterange[0], 0),
            "filters[datum][$lte]": addday(query.daterange[1], 0),
          }
        );
        setDateRangeFilter(filter);
      }
    }

    setLoadingDyn(false);
  };

  const getDateNoTime = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    return today;
  };

  useEffect(() => {
    if (seminarInputs.id) {
      downloadHandler("listfilesbyseminar", null, "list");
      getStoredClientData({
        seminar_id: seminarInputs.id,
      });
    }
  }, [seminarInputs.id, downloadHandler, getStoredClientData]);

  useEffect(() => {
    if (location.pathname === "/seminare/bestsemverwaltung") {
      getInvoiceData({
        "filters[bestell_datum][$gte]": new Date().setDate(
          new Date().getDate() - 45
        ),
        "fields[0]": "id_seminar",
        "fields[1]": "status",
      });
    }
  }, [location, getInvoiceData]);

  const exportGridData = (csvData) => {
    // convert to excel
    const wb = XLSX.read(csvData, {
      type: "string",
      raw: true,
    });
    XLSX.writeFile(wb, "export.xlsx");
  };

  const dateValidation = (date) => {
    if (isNaN(Date.parse(date))) {
      return true;
    } else {
      return false;
    }
  };

  const rangeValidation = (type) => {
    if (type === "daterange") {
      // check if end date is before start date
      if (seminarInputs?.end_datum && seminarInputs?.datum) {
        if (new Date(seminarInputs.end_datum) < new Date(seminarInputs.datum)) {
          return true;
        } else {
          return false;
        }
      }
    } else if (type === "timerange") {
      // check if end time is before start time
      if (seminarInputs?.end_zeit && seminarInputs?.anfangs_zeit) {
        if (seminarInputs.end_zeit < seminarInputs.anfangs_zeit) {
          return true;
        } else {
          return false;
        }
      }
    } else if (type === "lessthantoday") {
      // check if date is less than today
      if (seminarInputs?.datum) {
        if (new Date(seminarInputs.datum) < getDateNoTime()) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  return (
    <main>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex flex-1 justify-between mx-6 mt-4 h-min">
          <h3>
            {filter === "confirmed"
              ? "Bestätigte Seminare"
              : filter === "currentmonth"
              ? "Seminarverwaltung"
              : "Kompl. Seminarliste"}
          </h3>

          {filter === "currentmonth" && (
            <button
              onClick={() => {
                setOpen("add");
              }}
              className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Hinzufügen
            </button>
          )}

          {filter === "all" && (
            <button
              onClick={() => {
                exportGridData(
                  gridRefSeminar?.current?.api?.getDataAsCsv({
                    // Specify the columns to export
                    columnKeys: [
                      "datum_friendly_adjusted",
                      "end_datum_friendly_adjusted",
                      "anfangs_zeit_friendly_adjusted",
                      "end_zeit_friendly_adjusted",
                      "thema",
                      "raum_details.ort",
                      "raum_details.bezeichnung",
                      "referent_details",
                      "max_teilnehmer",
                    ],
                    onlySelected: true,
                    includeHeaders: true,
                  })
                );
              }}
              className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Export Seminarliste
            </button>
          )}
        </div>
      </div>
      <div>
        <div className="w-full px-6">
          <div className={`py-5`}>
            <div>
              <div className="sm:mt-0 text-sm text-left font-medium text-gray-700">
                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <form>
                      <div>
                        <div className="bg-white">
                          <div className="grid grid-cols-12 gap-x-6 gap-y-4">
                            {location.pathname ===
                            "/seminare/bestsemverwaltung" ? (
                              <SeminarConfirmedGrid
                                setQuery={setQuery}
                                query={query}
                                exactDateFilter={exactDateFilter}
                                dateRangeFilter={dateRangeFilter}
                                loadingDyn={loadingDyn}
                                filterHandler={filterHandler}
                                idFilter={idFilter}
                                seminarInputs={seminarInputs}
                                seminarSelection={
                                  invoiceSelection.length > 0 &&
                                  seminarSelection.length > 0
                                    ? seminarSelection.filter(
                                        (seminar) =>
                                          invoiceSelection.some(
                                            (invoice) =>
                                              invoice.attributes.id_seminar ===
                                                seminar.id &&
                                              (invoice.attributes.status ===
                                                "angelegt" ||
                                                invoice.attributes.status ===
                                                  "bezahlt")
                                          ) ||
                                          seminar.attributes.is_confirmed ===
                                            true
                                      )
                                    : []
                                }
                                seminarReferenteSelection={
                                  seminarReferenteSelection
                                }
                                setSeminarInputs={setSeminarInputs}
                                setSeminarReferentListInputs={
                                  setSeminarReferentListInputs
                                }
                                setOpen={setOpen}
                                invoiceSelection={invoiceSelection}
                              />
                            ) : (
                              <SeminarSelectGrid
                                gridRefSeminar={gridRefSeminar}
                                triggerSeminarSelect={triggerSeminarSelect}
                                setTriggerSeminarSelect={
                                  setTriggerSeminarSelect
                                }
                                selectSeminarOnFirstLoad={
                                  selectSeminarOnFirstLoad
                                }
                                setSelectSeminarOnFirstLoad={
                                  setSelectSeminarOnFirstLoad
                                }
                                setQuery={setQuery}
                                query={query}
                                exactDateFilter={exactDateFilter}
                                loadingDyn={loadingDyn}
                                filterHandler={filterHandler}
                                idFilter={idFilter}
                                seminarInputs={seminarInputs}
                                seminarSelection={seminarSelection.filter(
                                  (seminar) => {
                                    if (idFilter || exactDateFilter) {
                                      return true;
                                    } else {
                                      return (
                                        Date.parse(seminar.attributes.datum) >=
                                        Date.parse(getDateNoTime())
                                      );
                                    }
                                  }
                                )}
                                seminarReferenteSelection={
                                  seminarReferenteSelection
                                }
                                setSeminarInputs={setSeminarInputs}
                                setSeminarReferentListInputs={
                                  setSeminarReferentListInputs
                                }
                                setOpen={setOpen}
                                setEditSeminar={setEditSeminar}
                                editSeminar={editSeminar}
                                // filter={filter}
                              />
                            )}
                            {filter === "currentmonth" && (
                              <div className="col-span-12 flex items-center mt-10">
                                <SelectedSeminarInputs
                                  getSemPartData={getSemPartData}
                                  dateValidation={dateValidation}
                                  rangeValidation={rangeValidation}
                                  open={open}
                                  setOpen={setOpen}
                                  getData={getData}
                                  seminarInputs={seminarInputs}
                                  setSeminarInputs={setSeminarInputs}
                                  handleSeminarChange={handleSeminarChange}
                                  raumeSelection={raumeSelection}
                                  seminarReferentListInputs={
                                    seminarReferentListInputs
                                  }
                                  notify={notify}
                                  setSeminarReferentListInputs={
                                    setSeminarReferentListInputs
                                  }
                                  currentReferentHandler={
                                    currentReferentHandler
                                  }
                                  referentSelection={referentSelection}
                                  seminarReferenteSelection={
                                    seminarReferenteSelection
                                  }
                                  seminarParticipantsList={
                                    seminarParticipantsList
                                  }
                                  handleSeminarReferentChange={
                                    handleSeminarReferentChange
                                  }
                                  updateSeminarHandler={updateSeminarHandler}
                                  addSeminarReferentHandler={
                                    addSeminarReferentHandler
                                  }
                                  formErrorHandling={formErrorHandling}
                                  setFormErrorHandling={setFormErrorHandling}
                                  loading={loading}
                                  setLoading={setLoading}
                                  editSeminar={editSeminar}
                                  setEditSeminar={setEditSeminar}
                                  storedClientSelection={storedClientSelection}
                                  token={token}
                                  getInvoiceData={getInvoiceData}
                                  wordPressAudienceOptions={
                                    wordPressAudienceOptions
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(open === "add" || open === "copy") && (
        <SeminarAddModal
          dateValidation={dateValidation}
          rangeValidation={rangeValidation}
          open={open}
          setOpen={setOpen}
          getData={getData}
          seminarInputs={seminarInputs}
          setSeminarInputs={setSeminarInputs}
          handleSeminarChange={handleSeminarChange}
          raumeSelection={raumeSelection}
          seminarReferentListInputs={seminarReferentListInputs}
          setSeminarReferentListInputs={setSeminarReferentListInputs}
          currentReferentHandler={currentReferentHandler}
          referentSelection={referentSelection}
          seminarReferenteSelection={seminarReferenteSelection}
          handleSeminarReferentChange={handleSeminarReferentChange}
          updateSeminarHandler={updateSeminarHandler}
          addSeminarReferentHandler={addSeminarReferentHandler}
          formErrorHandling={formErrorHandling}
          setFormErrorHandling={setFormErrorHandling}
          loading={loading}
          setLoading={setLoading}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          referenteArray={referenteArray}
          setReferenteArray={setReferenteArray}
          referenteAddData={referenteAddData}
          setReferenteAddData={setReferenteAddData}
          wordPressAudienceOptions={wordPressAudienceOptions}
        />
      )}
    </main>
  );
};

export default EditSeminar;
